import React, { useCallback, useMemo } from "react";
import BlockContent from "../../components/BlockContent/BlockContent";
import Card from "../../components/Card/Card";
import { Col, Row } from "../../components/Grid";
import NoWelcomeProgramModal from "../../components/Modals/GettingStartedModals/NoWelcomeProgramModal/NoWelcomeProgramModal";
import PowerUpAndPlayModal from "../../components/Modals/GettingStartedModals/PowerUpAndPlayModal/PowerUpAndPlayModal";
import SelectDeviceModal from "../../components/Modals/GettingStartedModals/SelectDeviceModal/SelectDeviceModal";
import SelectMicrobitModal from "../../components/Modals/GettingStartedModals/SelectMicrobitModal/SelectMicrobitModal";
import SelectProgramModal from "../../components/Modals/GettingStartedModals/SelectProgramModal/SelectProgramModal";
import TransferCodeModal from "../../components/Modals/GettingStartedModals/TransferCodeModal/TransferCodeModal";
import VideoModal from "../../components/Modals/GettingStartedModals/VideoModal/VideoModal";
import YouWillNeedModal from "../../components/Modals/GettingStartedModals/YouWillNeedModal/YouWillNeedModal";
import PageModal, {
  PageModalParams,
  PageModalTypes
} from "../../components/PageModal/PageModal";
import YoutubeVideo, {
  YoutubeVideoProps
} from "../../components/YoutubeVideoBlock/YoutubeVideoBlock";
import {
  ContentTranslationSponsor,
  SanityLicence,
  SanityModal
} from "../../model/common";
import { SanityGettingStartedContentCard } from "../../model/landing-page";
import { useGatsbyLocation } from "../../utils/useGatsbyLocation";
import { useSessionSettings } from "../../utils/useSessionSettings";
import styles from "./GettingStartedContent.module.scss";
import { navigateWithUrlSearchParams } from "../../utils/navigate-with-params";

interface GettingStartedContentProps {
  licence?: SanityLicence;
  translationSponsors?: ContentTranslationSponsor[];
  content: SanityGettingStartedContentCard[];
  pageModals: SanityModal[] | undefined;
}

const GettingStartedContent = ({
  content,
  pageModals
}: GettingStartedContentProps) => {
  const contentComponents = content?.map(item => {
    switch (item._type) {
      case "gettingStartedPageContentCard":
        return <ContentCard key={item._key} content={item.content} />;
      default:
        throw new Error(
          `Unknown getting started page item: ${(item as any)._type}`
        );
    }
  });
  const [sessionSettings, setSessionSettings] = useSessionSettings();
  const handleModalClose = useCallback(
    (type: string) => {
      if (!sessionSettings) {
        return;
      }
      if (type === "powerUpAndPlay") {
        setSessionSettings({
          ...sessionSettings,
          gettingStartedReadyPrompt: true
        });
      }
      if (type === "getCodingDevice") {
        setSessionSettings({
          ...sessionSettings,
          getCodingDevicePrompt: true
        });
      }
    },
    [sessionSettings, setSessionSettings]
  );
  const renderBodyComponent = useCallback(
    (type, _rawContent, handleCloseModal) => {
      switch (type) {
        case PageModalTypes.PowerUpAndPlay:
          return (
            <PowerUpAndPlayModal
              content={_rawContent ?? []}
              onClose={handleCloseModal}
            />
          );
        case PageModalTypes.PowerUpAndPlayWelcomeProgram:
          return (
            <SelectMicrobitModal
              content={_rawContent ?? []}
              onClose={handleCloseModal}
            />
          );
        case PageModalTypes.GetCodingDevice:
          return (
            <SelectDeviceModal
              content={_rawContent ?? []}
              onClose={handleCloseModal}
            />
          );
        case PageModalTypes.GetCodingYouWillNeedComputer:
        case PageModalTypes.GetCodingYouWillNeedTablet:
          return (
            <YouWillNeedModal
              content={_rawContent ?? []}
              onClose={handleCloseModal}
            />
          );
        case PageModalTypes.GetCodingTransferComputer:
          return (
            <TransferCodeModal
              content={_rawContent ?? []}
              onClose={handleCloseModal}
              modalId="computer"
            />
          );
        case PageModalTypes.GetCodingTransferTablet:
          return (
            <TransferCodeModal
              content={_rawContent ?? []}
              onClose={handleCloseModal}
              modalId="tablet"
            />
          );
        case PageModalTypes.PowerUpAndPlayV2:
          return (
            <SelectProgramModal
              content={_rawContent ?? []}
              inputKey={"V2"}
              onClose={handleCloseModal}
            />
          );
        case PageModalTypes.PowerUpAndPlayV1:
          return (
            <SelectProgramModal
              content={_rawContent ?? []}
              inputKey={"V1"}
              onClose={handleCloseModal}
            />
          );
        case PageModalTypes.PowerUpAndPlayNoWelcome:
          return (
            <NoWelcomeProgramModal
              content={_rawContent ?? []}
              onClose={handleCloseModal}
            />
          );
        case PageModalTypes.AnimationVideo:
          return (
            <VideoModal
              content={_rawContent ?? []}
              onClose={handleCloseModal}
            />
          );
        default:
          throw new Error(`Unknown modal item: ${type}`);
      }
    },
    []
  );
  const { search } = useGatsbyLocation();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  const getPageModalOnOpen = useCallback(
    (modal: SanityModal) => {
      let queryParamValue = queryParams.get(PageModalParams.TransferCodeHelp);
      let onOpen;
      if (
        (queryParamValue === "computer" &&
          modal.type === PageModalTypes.GetCodingTransferComputer) ||
        (queryParamValue === "tablet" &&
          modal.type === PageModalTypes.GetCodingTransferTablet)
      ) {
        modal.showOnPageView = true;
        onOpen = () => {
          queryParams.delete(PageModalParams.TransferCodeHelp);
          navigateWithUrlSearchParams(queryParams);
        };
      }
      queryParamValue = queryParams.get(PageModalParams.YouWillNeed);
      if (
        (queryParamValue === "computer" &&
          modal.type === PageModalTypes.GetCodingYouWillNeedComputer) ||
        (queryParamValue === "tablet" &&
          modal.type === PageModalTypes.GetCodingYouWillNeedTablet)
      ) {
        modal.showOnPageView = true;
        onOpen = () => {
          queryParams.delete(PageModalParams.YouWillNeed);
          navigateWithUrlSearchParams(queryParams);
        };
      }
      return onOpen;
    },
    [queryParams]
  );
  const updateShowModalOnPageView = useCallback(
    (modal: SanityModal) => {
      // If there's a direct link to a specific device, don't ask the user
      // again what device they are using.
      if (modal.type === "getCodingDevice") {
        const queryParamValue = queryParams.get(PageModalParams.Device);
        if (
          queryParamValue &&
          ["computer", "android", "iOS"].includes(queryParamValue)
        ) {
          modal.showOnPageView = false;
        }
      }
    },
    [queryParams]
  );
  return (
    <>
      <div className={styles.root}>{contentComponents}</div>
      {pageModals?.map(modal => {
        const onOpen = getPageModalOnOpen(modal);
        updateShowModalOnPageView(modal);
        return (
          <PageModal
            key={modal._key}
            data={modal}
            modalDismissedThisSession={
              (modal.type === PageModalTypes.PowerUpAndPlay &&
                sessionSettings?.gettingStartedReadyPrompt) ||
              (modal.type === PageModalTypes.GetCodingDevice &&
                sessionSettings?.getCodingDevicePrompt)
            }
            onClose={() => handleModalClose(modal.type)}
            onOpen={onOpen}
            renderBodyComponent={renderBodyComponent}
          />
        );
      })}
    </>
  );
};

interface ContentCardProps {
  content: SanityGettingStartedContentCard;
}

const ContentCard = ({ content }: ContentCardProps) => (
  <Row justify="center" className={styles.card}>
    <Col sm={12}>
      <Card type="main">
        <BlockContent
          content={content}
          cap="3/4"
          customEmbeds={{
            youtubeVideo: ({ node }: YoutubeVideoProps) => (
              <div className={styles.embedContainer}>
                <YoutubeVideo node={node} />
              </div>
            )
          }}
        />
      </Card>
    </Col>
  </Row>
);

export default GettingStartedContent;
