import React, { useCallback, useState } from "react";
import GettingStartedRadio from "../../../../components/GettingStartedRadio/GettingStartedRadio";
import { SanityCallToAction, SanityImage } from "../../../../model/common";
import {
  findContent,
  SanityLandingPageString
} from "../../../../model/landing-page";
import { navigateWithParamsForFiltering } from "../../../../utils/navigate-with-params";
import { Col, Row } from "../../../Grid";
import ModalBody from "../../../Modal/ModalBody/ModalBody";
import ModalFooter from "../../../Modal/ModalFooter/ModalFooter";
import { PageModalBodyProps, triggerModal } from "../../../PageModal/PageModal";
import WithSpacing from "../../../Spacing/Spacing";
import StepperStyledButton from "../../../Stepper/StepperButton/StepperStyledButton";
import Text from "../../../Text/Text";
import styles from "./SelectDeviceModal.module.scss";

const SelectDeviceModal = ({ content, onClose }: PageModalBodyProps) => {
  const [device, setDevice] = useState<"computer" | "iOS">();
  const [text] = findContent<SanityLandingPageString>("text", content);
  const [itemOneImage, itemTwoImage] = findContent<SanityImage>(
    "itemImages",
    content
  );
  const [
    itemOneTitle,
    itemOneSubtitle,
    itemTwoTitle,
    itemTwoSubtitle
  ] = findContent<SanityLandingPageString>("itemText", content);

  const [itemOneCta, itemTwoCta] = findContent<SanityCallToAction>(
    "itemCTAs",
    content
  );

  const handleChange = useCallback((value: string) => {
    if (value === "computer" || value === "iOS") {
      setDevice(value);
    }
  }, []);

  const handleClick = useCallback(() => {
    if (device === "computer" && itemOneCta.targetModal) {
      navigateWithParamsForFiltering({ device });
      triggerModal(itemOneCta.targetModal);
    } else if (device === "iOS" && itemTwoCta.targetModal) {
      navigateWithParamsForFiltering({ device });
      triggerModal(itemTwoCta.targetModal);
    }
    onClose();
  }, [device, itemOneCta.targetModal, itemTwoCta.targetModal, onClose]);

  const items = [
    {
      id: "device",
      title: itemOneTitle.value,
      subtitle: itemOneSubtitle.value,
      image: itemOneImage,
      selected: device === "computer",
      value: "computer",
      onChange: handleChange
    },
    {
      id: "device",
      title: itemTwoTitle.value,
      subtitle: itemTwoSubtitle.value,
      image: itemTwoImage,
      selected: device === "iOS",
      value: "iOS",
      onChange: handleChange,
      imageScale: 0.8
    }
  ];

  return (
    <div className={styles.root}>
      <ModalBody>
        <WithSpacing>
          <div>
            <Text variant="default">{text.value}</Text>
            <Row>
              {items.map((item, index) => (
                <Col key={index} className={styles.item} xs={12} sm={6}>
                  <GettingStartedRadio
                    id="microbit"
                    value={item.value}
                    title={item.title}
                    subtitle={item.subtitle}
                    image={item.image}
                    onChange={item.onChange}
                    selected={item.selected}
                    imageScale={item.imageScale}
                    tabbable={!device ? index === 0 : item.selected}
                  />
                </Col>
              ))}
            </Row>
          </div>
        </WithSpacing>
      </ModalBody>
      <ModalFooter>
        <StepperStyledButton
          onClick={handleClick}
          text={device === "computer" ? itemOneCta.title : itemTwoCta.title}
          bold
          disabled={!device}
        />
      </ModalFooter>
    </div>
  );
};

export default SelectDeviceModal;
